import { AxiosError } from 'axios'
import { MakeAxiosPaginatedResponse } from '../../../services/Service.types'
import { PriceOfferShort } from '../../../services/api/price-offer/PriceOffer.types'
import { addOrdersToPriceOffer } from '../../../services/api/price-offer/PriceOffer.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const usePriceOfferAddOrders = (withPermanentStorage: boolean | undefined = undefined, onSuccess?: () => void) => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    return useMutation<void, AxiosError, { id: number; orders: number[] }>(
        ['priceOfferAddOrders'],
        ({ id, orders }) => {
            return addOrdersToPriceOffer(axiosInstance, id, orders, {
                params: {
                    withPermanentStorage: typeof withPermanentStorage === 'boolean' ? withPermanentStorage : undefined
                }
            })
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries<MakeAxiosPaginatedResponse<PriceOfferShort>>(['priceOffers'])
                onSuccess?.()
            }
        }
    )
}

export default usePriceOfferAddOrders
